import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import Layout from "../layout";
import Cart from "../components/Cart/Cart";

function CartPage({ location }) {
  return (
    <Layout location={location}>
      <>
        <Helmet title="Stuntpuppy" />
        <main className="flex flex-col h-screen">
          <Cart />
        </main>
      </>
    </Layout>
  );
}

export default CartPage;
